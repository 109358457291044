<template>
     <transition name="fadeed">
        <!-- <section id="about-section" class="px-0 lg:px-0 py-0 border-t border-stone-500 relative"> -->
      <div class="AboutSection">
        <div class="AboutSection-xlef"></div>
             <div class="AboutSection-one">最新优质推荐</div>
             <div class="AboutSection-two">海量优质单身用户，在这里遇见和你一样优秀</div>
             <div class="AboutSection-Single">Latest high-quality recommendations</div>
             <div class="AboutSection-img"> <img src="../assets/gengd.png" @click="onHome" alt=""></div>

            <div  >
                <div class="AboutSection-p" >
              <div class="AboutSection-chil" v-for="item in formlist" :key="item.index" @click="ongoDEtail(item.id)"> 
                <img v-if="item.show" class="AboutSection-gif" src="../assets//007.gif" alt="">
                <img :style="item.show?'border: 6px solid #783DF9;  border-radius: 50%; transform: translateY(0%)!important; transition: all 0.5s ease-out; opacity: 1!important;':'transition: all .5s ease-out; transform: translateY(0%)!important; '"  class="AboutSection-imgx"  @mouseenter.stop="handleMouseEnter(item)" @mouseleave.stop="handleMouseLeave(item)" :src="item.headIcon" alt="">
                <div :class="{test:true,testenter}"  v-if="item.show" class="AboutSection-text">{{ item.nickNameStatus==2? item.nickName:'***' }}</div>
                <div  :class="{test:true,testenter}"  v-if="item.show" class="AboutSection-textl">{{ item.registerAddress?item.registerAddress+'|':''}}{{  item.age?item.age+'岁'+'|':''}}{{ item.educationDesc?item.educationDesc+'|':'' }}{{ item.maritalStatusDesc}}</div>
              </div>
              </div>    
              <div class="AboutSection-px">
              <div class="AboutSection-chil" v-for="item in formlisttwo" :key="item.index" @click="ongoDEtail(item.id)"> 
                <img v-if="item.show" class="AboutSection-gif" src="../assets//007.gif" alt="">
                <img :style="item.show?'border: 6px solid #783DF9;  border-radius: 50%; transform: translateY(0%)!important; transition: all 0.5s ease-out; opacity: 1!important;':'transition: all .5s ease-out; transform: translateY(0%)!important; '"  class="AboutSection-imgx"  @mouseenter.stop="handleMouseEnter(item)" @mouseleave.stop="handleMouseLeave(item)" :src="item.headIcon" alt="">
                <div :class="{test:true,testenter}"  v-if="item.show" class="AboutSection-text">{{  item.nickNameStatus==2? item.nickName:'***' }}</div>
                <div :class="{test:true,testenter}"  v-if="item.show" class="AboutSection-textl">{{ item.registerAddress?item.registerAddress+'|':''}}{{  item.age?item.age+'岁'+'|':''}}{{ item.educationDesc?item.educationDesc+'|':'' }}{{ item.maritalStatusDesc}}</div>
              </div>
              </div>
              <div class="AboutSection-p">
              <div class="AboutSection-chil" v-for="item in formlistthr" :key="item.index" @click="ongoDEtail(item.id)"> 
                <img v-if="item.show" class="AboutSection-gif" src="../assets//007.gif" alt="">
                <img :style="item.show?'border: 6px solid #783DF9;  border-radius: 50%; transform: translateY(0%)!important; transition: all 0.5s ease-out; opacity: 1!important;':'transition: all .5s ease-out; transform: translateY(0%)!important; '" class="AboutSection-imgx"  @mouseenter.stop="handleMouseEnter(item)" @mouseleave.stop="handleMouseLeave(item)" :src="item.headIcon" alt="">
                <div :class="{test:true,testenter}"  v-if="item.show" class="AboutSection-text">{{  item.nickNameStatus==2? item.nickName:'***' }}</div>
                <div :class="{test:true,testenter}"  v-if="item.show" class="AboutSection-textl">{{ item.registerAddress?item.registerAddress+'|':''}}{{  item.age?item.age+'岁'+'|':''}}{{ item.educationDesc?item.educationDesc+'|':'' }}{{ item.maritalStatusDesc}}</div>
              </div>
              </div>
            </div>
      </div>
      
	<!-- </section> -->
     </transition>
    <!-- About section start -->
	
	<!-- About section end -->
</template>

<script>
import {getPCAccountList,highQualityAccountList }from '../axios/dev'
import router from "../router/router"
export default {
    data(){
        return{
            testShowxr:false,

formlist:[],
formlisttwo:[],
formlistthr:[]

        }
    },
    mounted(){
        // window.addEventListener("scroll", this.handleScroll);
        if(!localStorage.getItem('token')){
            getPCAccountList().then(res=>{
           
            this.formlist = this.formlist.concat(res.data.slice(0,5))
            this.formlisttwo = this.formlisttwo.concat(res.data.slice(5,9))
            this.formlistthr = this.formlistthr.concat(res.data.slice(9,14))
        
        })

        }else{
            highQualityAccountList().then(res=>{
            this.formlist = this.formlist.concat(res.data.list.slice(0,5))
            this.formlisttwo = this.formlisttwo.concat(res.data.list.slice(5,9))
            this.formlistthr = this.formlistthr.concat(res.data.list.slice(9,14))
        
      
        })
        }
      
    },
    methods:{
        
        ongoDEtail(item){
            if(localStorage.getItem('token')){
          router.push({
            path: '/Membershipdetails',
            query: {
              id: item
            }
             })

        }else{
          router.push({
            path: '/shipdetails',
            query: {
              id: item
            }
             })
        }

        },
        onHome(){
            router.push({
            path: '/Home',
          
             })

        },
        handleMouseEnter(item) {
            item.show=true
    //         console.log(item);
    //   console.log('鼠标移入');
    },
    handleMouseLeave(item) {
        // console.log(item);
        item.show=false
    //   console.log('鼠标移出');
    }
    }

}
</script>

<style  scoped>
.AboutSection{
    width: 100%;
    height: 1206px;
    background:url('https://online-miniapp-1304146125.cos.ap-beijing.myqcloud.com/98fcde25-877f-43a6-9456-0492e614e8a8.png') no-repeat center 0px ;
	background-position: center 0px;
    background-size: cover;

}
.AboutSection-xlef{
    width: 100%;
    height: 1px;
    background:url('https://online-miniapp-1304146125.cos.ap-beijing.myqcloud.com/98fcde25-877f-43a6-9456-0492e614e8a8.png') no-repeat center 0px ;
	background-position: center 0px;
    background-size: cover;
    margin-top: 0px;

}
.AboutSection-one{
    margin: 132px auto; 
    font-family: PingFangSC, PingFang SC;
font-weight: 500;
font-size: 56px;
color: #051850;
line-height: 78px;
text-align: center;
font-style: normal;

}
.AboutSection-two{

    /* margin: 0px auto;  */
    font-family: PingFangSC, PingFang SC;
font-weight: 400;
font-size: 24px;
margin-top: -100px;
/* border: 1px solid #ccc; */
color: rgba(5,24,80,0.6);
line-height: 33px;
text-align: center;
font-style: normal;


}
.AboutSection-img{
    width: 106px;
    height: 113px;
    float: right;
    margin-right: 149px;
    margin-top: -80px;
}
.AboutSection-Single {

font-family: PingFangSC, PingFang SC;
font-weight: 400;
font-size: 24px;
color: rgba(0, 0, 0, 0.1);

margin-top: -75px;
letter-spacing: 6px;
text-align: center;
font-style: normal;
text-transform: uppercase;
}
.AboutSection-p{
    width: 90%;
    height: 208px;
    margin-top: 100px;
    /* background-color: rebeccapurple; */
    /* margin-top: 60px; */
    margin-left: 5%;
    display: flex;
    
}
.AboutSection-px{
    width: 70%;
    height: 208px;
    margin-left: 15%;
    margin-top: 30px;
    display: flex;
}
.AboutSection-chil{
    flex: 1;
   
    /* border: 1px solid #051850; */
    position: relative;
}
.AboutSection-chil .AboutSection-imgx{
    width: 178px;
    height: 178px;
    margin-left: 100px;
    border-radius: 50%;
   
}
.AboutSection-gif{

    width: 223px;
    height: 218px;
    position: absolute;
    top: -20px;
    left: 78px;
    transform:translateY(0%)!important;; 
      transition: all 2s ease-out; 
       opacity: 1!important;
}
.AboutSection-text{
    width: 100%;
    height: 30px;
    /* margin-left: 25%; */
    /* background-color: darkcyan; */
    text-align: center;
    line-height: 30px;
    /* margin-top: 20px; */

}
.AboutSection-textl{
    width: 100%;
    height: 30px;
    text-align: center;
    line-height: 30px;
}
.testenter{
transform: translateY(0%)!important;  
opacity: 1!important;
transition: all 1.5s ease-out;

}

.test{  
    transition: all 1.5s ease-out;
        transform: translateY(70%);  
        opacity: 0.5;   
      }
</style>