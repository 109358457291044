<template>
    <transition>
        <section>
            <div class="AboutSection">
                <div class="AboutSection-one">脱单故事</div>
                <div class="AboutSection-Single">The Story of Detachment</div>
                <div class="AboutSection-two">专业红娘老师做您的专属倾听者，为倾听你而来，理解你的善良，温暖你的内心</div>
                <div class="AboutSection-img"> <img @click="gotoBackx" src="../assets/gengd.png" alt=""></div>
                <div style="margin-top: 30px;">
                    <div class="AboutSection-p" >
                        <div class="AboutSection-xsl">
                            <div class="AboutSection-ers" v-for=" item in formlist" :key="item.id" @click="onClickae(item.id)" @mouseenter.stop="handleMouseEnter(item)" @mouseleave.stop="handleMouseLeave(item)"    >
                                <img :src="item.images[0]"
                                    alt="" >
                                <div   v-if="item.show" :style="item.show?'transition: opacity 2s linear;transition: all 1s ease-out;   box-shadow: 1px 10px 10px 0px #BEBBBB;':'transition: all .5s ease-out;'" class="AboutSection-ers-xwe">

                                    <div class="AboutSection-onex">
                                       {{ item.title }}
                                    </div>
                                    <div class="AboutSection-exld">
                                        {{ item.subTitle }}
                                    </div>
                                    <div class="AboutSection-xer">查看详情</div>
                                </div>
                            </div>
                           
                        </div>
 
                        <div class="AboutSection-xk" v-for="items in formlistBig" @click="onClickae(items.id)" :key="items.id" @mouseenter.stop="handleMouseEnter(items)" @mouseleave.stop="handleMouseLeave(items)">
                            <img  :src="items.images[0]"
                                    alt="">

                                 <div class="AboutSection-ers-xler"  v-if="items.show">

                                 <div class="AboutSection-onexx">
                                       {{ items.title }}
                                    </div>
                                    <div class="AboutSection-exldx">
                                        {{ items.subTitle }}
                                    </div>
                                    <div class="AboutSection-xerx">查看详情</div>
                                 </div>
                                   

                        </div>
                        <div class="AboutSection-xsl">
                            <div class="AboutSection-ers" v-for=" item in formlistx"  @click="onClickae(item.id)" :key="item.id" @mouseenter.stop="handleMouseEnter(item)" @mouseleave.stop="handleMouseLeave(item)"    >
                                <img :src="item.images[0]"
                                    alt="" >
                                <div   v-if="item.show" :style="item.show?'transition: opacity 2s linear;transition: all 1s ease-out;   box-shadow: 1px 10px 10px 0px #BEBBBB;':'transition: all .5s ease-out;'" class="AboutSection-ers-xwe">

                                    <div class="AboutSection-onex">
                                       {{ item.title }}
                                    </div>
                                    <div class="AboutSection-exld">
                                        {{ item.subTitle }}
                                    </div>
                                    <div class="AboutSection-xer">查看详情</div>
                                </div>
                            </div>
                           
                        </div>
                        <div class="AboutSection-xerldd"></div>

                      
                        
                        
                        
                    </div>
                </div>
            </div>
        </section>
    </transition>

</template>

<script>
import { add } from "../utils/deta.js";
import router from "../router/router"
import {WonderfulAtlasList } from '../axios/dev'
// v-for="item in formlist" :key="item.teacherId"  @mouseenter.stop="handleMouseEnter(item)" @mouseleave.stop="handleMouseLeave(item)"
export default {
    data() {
        return {
            testShowxr: false,

            formlist: [],
            formlistBig:[],
            formlistx:[]



        }
    },


    mounted() {
        // this.scrollInit();
        // this.add()
        WonderfulAtlasList({
            contentType:2,
            size:20,
            window:window.screen.width,
            current:1
          }).then(res=>{
            this.formlist = this.formlist.concat(res.data.list.slice(0,4))
            this.formlistBig = this.formlistBig.concat(res.data.list.slice(4,5))
            this.formlistx = this.formlistx.concat(res.data.list.slice(5,9))
            // console.log(this.formlistBig,'2222222222');
            console.log( this.formlist );
            //  this.formlist=res.data.list

          
           })
    },
    methods: {
        onClickae(item){
            console.log(item);
            router.push({
            path: '/Thesinglestory',
            query: {
              id: item
            }
             })
        },
        gotoBackx(){
            localStorage.setItem('ifNum', 5);
            router.push({
            path: '/singleStoryPlus',
          
             })
        },
        handleMouseEnter(item) {
            item.show = true
        },
        handleMouseLeave(item) {
            item.show = false

        },
        scrollInit() {
            // 获取要绑定事件的元素
            const nav = document.getElementById("nav")
            var flag; // 鼠标按下
            var downX; // 鼠标点击的x下标
            var scrollLeft; // 当前元素滚动条的偏移量
            nav.addEventListener("mousedown", function (event) {
                flag = true;
                downX = event.clientX; // 获取到点击的x下标
                scrollLeft = this.scrollLeft; // 获取当前元素滚动条的偏移量
            });
            nav.addEventListener("mousemove", function (event) {
                if (flag) { // 判断是否是鼠标按下滚动元素区域
                    var moveX = event.clientX; // 获取移动的x轴
                    var scrollX = moveX - downX; // 当前移动的x轴下标减去刚点击下去的x轴下标得到鼠标滑动距离
                    this.scrollLeft = scrollLeft - scrollX // 鼠标按下的滚动条偏移量减去当前鼠标的滑动距离
                    console.log(scrollX)
                }
            });
            // 鼠标抬起停止拖动
            nav.addEventListener("mouseup", function () {
                flag = false;
            });
            // 鼠标离开元素停止拖动
            nav.addEventListener("mouseleave", function (event) {
                flag = false;
            });
        },



        // timestampToTime


        // 时间戳转换函数


        // 示例使用
        // 输出：2021-05-26 16:11:25

    },


}
</script>

<style scoped>
.AboutSection {
    /* width: 100%; */
    overflow: hidden;
    height: 856px;
    background: linear-gradient(360deg, #F5F3FF 50%, rgba(204, 190, 255, 0) 100%);
    /* border: 1px solid #ccc; */
    margin-top: 30px;
    position: relative;
    /* background-color: #F1EEFF; */
}

.AboutSection-one {
    margin: 20px auto;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 56px;
    color: #051850;
    line-height: 82px;
    text-align: center;
    font-style: normal;

}

.AboutSection-Single {

    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 24px;
    color: rgba(0, 0, 0, 0.1);
    margin-top: -35px;
    letter-spacing: 6px;
    text-align: center;
    font-style: normal;
    text-transform: uppercase;
}

.AboutSection-two {

    /* margin: 0px auto;  */
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 24px;
    margin-top: 10px;
    /* border: 1px solid #ccc; */
    color: #999;
    line-height: 33px;
    text-align: center;
    font-style: normal;


}

.AboutSection-p {
    /* width: 100%; */
    height: 624px;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
    /* background-color: rebeccapurple; */
    display: flex;
}

.AboutSection-xsl {
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    position: relative;

}
.AboutSection-img{
   width: 106px;
   height: 113px;
   position: absolute;
   right: 149px;
   top: 80px;
   /* float: right;
   margin-right: 149px;
   margin-top: -80px; */
}
/* .AboutSection-xslxxx{
 
  
    display: flex;
    flex-wrap: wrap;
    position: relative;
} */



.AboutSection-ers {
    width: 46%;
    height: 300px;
    border-radius: 10px;

    margin-left: 20px;
    text-align: center;
    position: relative;
    /* border: 1px solid #ccc; */
}

.AboutSection-ers img {
    border-radius: 10px;
    width: 100%;
    height: 100%;

}

.AboutSection-ers-xwe {
    width: 100%;
    /* margin-left: 20px; */
    height: 300px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
}

.AboutSection-onex {
    width: 100%;
    height: 50px;
    /* background-color: darkblue; */
    text-align: center;
    line-height: 50px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 24px;
    color: #FFFFFF;
    font-style: normal;
    margin-top: 50px;

}

.AboutSection-exld {
    width: 90%;
    
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 24px;
    text-align: justify;
    font-style: normal;
    margin-left: 22px;
    margin-top: 20px;

    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
.AboutSection-exldx {
    width: 473px;
    
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 24px;
  
    font-style: normal;
    margin-left: 128px;
    margin-top: 20px;
       
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}


.AboutSection-xer {
    width: 80%;
    height: 48px;
    background-color: #783DF9;
    text-align: center;
    line-height: 48px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    border-radius: 10px;
    color: #FFFFFF;
    /* margin-left: 80px;
    margin-top: 20px; */
    margin: 30px auto;
    font-style: normal;
    text-transform: uppercase;
}
.AboutSection-xerx {
    width: 80%;
    height: 48px;
    background-color: #783DF9;
    text-align: center;
    line-height: 48px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    border-radius: 10px;
    color: #FFFFFF;
   margin: 30px auto;
    font-style: normal;
    text-transform: uppercase;
}
.AboutSection-xk{
    width: 55%;
height: 624px;
border-radius: 21px;
position: relative;
}
.AboutSection-xk img{
    width: 100%;
    height: 98%;
    border-radius: 10px;
    margin-bottom: 10px;
    
}
.AboutSection-ers-xler{
    width: 100%;
    height: 98%;
    border-radius: 10px;
    margin-bottom: 10px;
background: rgba(0, 0, 0, 0.6);
position: absolute;
top: 0;
left: 0;
}
.AboutSection-onexx {
    width: 100%;
    height: 50px;
    /* background-color: darkblue; */
    text-align: center;
    line-height: 50px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 24px;
    color: #FFFFFF;
    font-style: normal;
    margin-top: 200px;

}
/* .AboutSection-xerldd{
    width: 1900px;
    height: 800px;
    overflow: hidden;
    float: right;
    background-color: red;
} */
</style>