<template>

    <div>
        <v-overlay
          v-model="overlay"
          class="align-center justify-center" >
            <div  class="center-xkl">
                <img src="https://test-cos.ynhlminiapp.com/05b3d3ab-cfd1-49d7-bd32-8df47ed6e155.gif" alt="">
                <img src="https://online-miniapp-1304146125.cos.ap-beijing.myqcloud.com/7d634670-8774-4280-ad5b-f2de1a11e876.png" alt="">
                <img @click="quoverlay" src="https://test-cos.ynhlminiapp.com/a56ad8ba-4e32-4da6-a336-6280c67e317a.png" alt="">
            </div>
         
        </v-overlay>
    </div>
</template>
<script> 
 export default{
    data(){
        return{
            overlay:true

        }
    },
    methods:{
        quoverlay(){
            
            this.overlay=false
        }
        
    }
 }
</script>

<style scoped>

.center-xkl{
    width: 360px;
height: 360px;
border-radius: 8px;
position: relative;
background:url('https://test-cos.ynhlminiapp.com/24412cc9-1d5c-4c7a-b68d-5fda1627fac1.png') no-repeat center 0px ;
	background-position: center 0px;
    background-size: cover;
}
.center-xkl img{
    width: 100%;
    height: 100%;
}
.center-xkl img:nth-child(2){
   width: 176px;
   height: 176px;
   margin: -260px auto;
}
.center-xkl img:nth-child(3){
   width: 40px;
   height: 40px;
   margin: 370px auto;
}

</style>