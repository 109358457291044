<template>
	<div>
        <NavbarElement></NavbarElement>
		<main-section></main-section>
	<!-- Main section end -->

	<!-- About section start -->
	<about-section :class="{test:true,testenter:testShowxr}" ></about-section>
	<!-- About section end -->
	<SinglesFellowship  ></SinglesFellowship>
	<!-- Services section start -->
	<portfolio-section  ></portfolio-section>
	<highMatchmakers></highMatchmakers>
	<LiveReplay></LiveReplay>
	<Highendlove></Highendlove>
	<singleStory></singleStory>
	<Getknow></Getknow>
	<!-- <service-section :class="{test:true,testenter:testShow2}" ></service-section> -->
	
	
	<!-- Services section end -->

	<!-- Portfolio section start -->

	<!-- Portfolio section end -->


	<!-- Contact section start -->
		<!-- <contact-section  :class="{testx:true,testenter:testShowx}" ref="testref" ></contact-section> -->
		<!-- <Home></Home> -->

	</div>

	<!-- Main section start -->
	
	<!-- Contact section end -->

</template>

<script>
// import router from "../../router/router"
import NavbarElement from '@/components/NavbarElement.vue';
import MainSection from '@/sections/MainSection.vue';
import AboutSection from '@/sections/AboutSection.vue';
import Highendlove from '@/sections/Highendlove.vue';


import SinglesFellowship from '@/sections/SinglesFellowship.vue';
import highMatchmakers from '@/sections/highMatchmakers.vue';
import LiveReplay from '@/sections/LiveReplay.vue';
import singleStory from '@/sections/singleStory.vue';
import Getknow from '@/sections/Getknow.vue';


import ServiceSection from '@/sections/ServiceSection.vue';
import PortfolioSection from '@/sections/PortfolioSection.vue';
import ContactSection from '@/sections/ContactSection.vue';
import Home from '@/sections/Home.vue';

export default {
	components: {
		MainSection,
		singleStory,
		Getknow,
		AboutSection,
		highMatchmakers,
		ServiceSection,
		LiveReplay,
		PortfolioSection,
		ContactSection,
		Highendlove,
		SinglesFellowship,
		NavbarElement,
		Home
	},
	data(){
		return{
			currentScroll:"",
			testShow:false,
			testShowx:false,
			testShowxr:false,
			testShow2:false
			
		}
	},
	
	mounted(){
		// router.go(0)
		window.addEventListener("scroll", this.handleScroll);
		// console.log(this.$refs.testref.offsetTop);
		
	},
	methods:{
		handleScroll(){
			// console.log(this.currentScroll);
	
			this.currentScroll =window.pageYOffset
			// console.log(this.currentScroll);
			if (this.currentScroll >504) { //当前滚动位置到达testref的时候，显示div（100作为调整用）
        this.testShow = true;
      }else{
		this.testShow = false;
	  }
	  if(this.currentScroll >1800){
		this.testShowx=true
	  }else{
		this.testShowx=false
	  }
	  if(this.currentScroll >120){
			this.testShowxr=true
		}else{
			this.testShowxr=false
		}


		if(this.currentScroll >500){
			this.testShow2=true
		}else{
			this.testShow2=false
		}
		}

		
	}

}
</script>

<style scoped>
      .testenter{

        transform: translateY(0%)!important;  
        opacity: 1!important;
        transition: all 1.5s ease-out;
		
      }
      .testl{  
		-webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        /* transform: translateY(70%);   */
        opacity: 0.5;   
      }

	  .test{  
		
    
        transform: translateY(70%);  
        opacity: 0.5;   
      }
	  .testx{  
		opacity: .5;
  transform: scale(1.5);
      /* transform:skew(45deg); */
        /* transform: translateY(70%);   */
        /* opacity: .5;    */
      }

</style>