<template>
  <transition>
    <section>
      <div class="AboutSection">
        <div class="AboutSection-one">直播回放</div>
        <div class="AboutSection-Single">Live playback</div>
        <div class="AboutSection-two">专业红娘老师做您的专属倾听者，为倾听你而来，理解你的善良，温暖你的内心</div>
        <!-- <div class="AboutSection-img"> <img src="../assets/gengd.png" alt=""></div> -->

        <div style="margin-top: 30px;">
          <div class="AboutSection-p">

            <div class="AboutSection-xsl" id="nav">
              <div class="AboutSection-chuld"
                :style="item.show ? 'width: 500px;  height: 560px;    transform: scale(1);  transition: all 1s ease-out;  opacity: 1!important;  box-shadow: 1px 10px 10px 0px #BEBBBB;' : 'transition: all .5s ease-out; height: 516px;'"
                v-for="item in formlist" :key="item.teacherId" @click="gotoBack(item)" @mouseenter.stop="handleMouseEnter(item)"
                @mouseleave.stop="handleMouseLeave(item)">
                <img :style="item.show ? 'height: 560px;' : 'height: 516px;'" :src="item.videoCover" alt="">
                <img
                  :src="item.show ? 'https://test-cos.ynhlminiapp.com/38933418-49c2-45e4-bb55-0bd35933b8f1.png' : 'https://test-cos.ynhlminiapp.com/16c69f4e-2769-4afe-a48e-b1799f837f6a.png'"
                  alt="">
                <img
                  :src="item.show ? 'https://test-cos.ynhlminiapp.com/32dd880a-585c-4d3d-88ae-457c467040b3.png' : 'https://test-cos.ynhlminiapp.com/b9db38a2-2f9b-4589-9cb3-8e7c61beb80b.png'"
                  alt="">
                <span :style="item.show ? 'color: #000;' : 'color: #fff;'">点赞人数 {{ item.likeNum }}</span>
              </div>


            </div>

          </div>
        </div>



      </div>

    </section>
  </transition>
  <!-- About section start -->

  <!-- About section end -->
</template>

<script>
   import router from "../router/router"
import { add } from "../utils/deta.js";
import { Vue3SeamlessScroll } from "vue3-seamless-scroll";
import { getVideoPlayBackList } from '../axios/dev'
export default {
  data() {
    return {
      testShowxr: false,

      formlist: [],



    }
  },


  mounted() {
    this.scrollInit();
    // this.add()
    getVideoPlayBackList({

    }).then(res => {
      this.formlist = res.data


    })
  },
  methods: {
    gotoBack(item){
      // console.log(item);
router.push({
           path: '/moonDetails',
           query: {
             id: item.teacherId
           }
            })
   },
    handleMouseEnter(item) {
      item.show = true
    },
    handleMouseLeave(item) {
      item.show = false

    },
    scrollInit() {
      // 获取要绑定事件的元素
      const nav = document.getElementById("nav")
      var flag; // 鼠标按下
      var downX; // 鼠标点击的x下标
      var scrollLeft; // 当前元素滚动条的偏移量
      nav.addEventListener("mousedown", function (event) {
        flag = true;
        downX = event.clientX; // 获取到点击的x下标
        scrollLeft = this.scrollLeft; // 获取当前元素滚动条的偏移量
      });
      nav.addEventListener("mousemove", function (event) {
        if (flag) { // 判断是否是鼠标按下滚动元素区域
          var moveX = event.clientX; // 获取移动的x轴
          var scrollX = moveX - downX; // 当前移动的x轴下标减去刚点击下去的x轴下标得到鼠标滑动距离
          this.scrollLeft = scrollLeft - scrollX // 鼠标按下的滚动条偏移量减去当前鼠标的滑动距离
          console.log(scrollX)
        }
      });
      // 鼠标抬起停止拖动
      nav.addEventListener("mouseup", function () {
        flag = false;
      });
      // 鼠标离开元素停止拖动
      nav.addEventListener("mouseleave", function (event) {
        flag = false;
      });
    },



    // timestampToTime


    // 时间戳转换函数


    // 示例使用
    // 输出：2021-05-26 16:11:25

    handleMouseEnter(item) {
      item.show = true
    },
    handleMouseLeave(item) {
      item.show = false

    }
  },


}
</script>

<style scoped>
.AboutSection {
  width: 100%;
  height: 856px;
  background: linear-gradient(360deg, #F5F3FF 50%, rgba(204, 190, 255, 0) 100%);
  /* border: 1px solid #ccc; */

  /* background-color: #F1EEFF; */
}

.AboutSection-one {
  margin: 20px auto;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 56px;
  color: #051850;
  line-height: 82px;
  text-align: center;
  font-style: normal;

}

.AboutSection-Single {

  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.1);
  margin-top: -35px;
  letter-spacing: 6px;
  text-align: center;
  font-style: normal;
  text-transform: uppercase;
}

.AboutSection-two {

  /* margin: 0px auto;  */
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 24px;
  margin-top: 10px;
  /* border: 1px solid #ccc; */
  color: #999;
  line-height: 33px;
  text-align: center;
  font-style: normal;


}

.AboutSection-img {
  width: 106px;
  height: 113px;
  float: right;
  margin-right: 149px;
  margin-top: -80px;
}

.AboutSection-p {
  width: 100%;
  height: 740px;
  display: flex;
}

.AboutSection-xsl {
  width: 90%;
  margin-left: 10%;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;

}

.AboutSection-chuld {
  width: 460px;
  /* height: 516px; */
  flex-shrink: 0;
  margin-left: 20px;
  /* border: 1px solid #051850; */
  border-radius: 10px;
  position: relative;

}

.AboutSection-chuld img:nth-child(1) {
  border-radius: 10px;
  width: 100%;
  height: 516px;
}

.AboutSection-chuld img:nth-child(2) {
  width: 66px;
  height: 66px;
  position: absolute;
  bottom: 20px;
  left: 30px;
}

.AboutSection-chuld img:nth-child(3) {
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: 37px;
  left: 218px;
}

.AboutSection-chuld span:nth-child(4) {

  font-family: AlibabaPuHuiTiR;
  font-size: 24px;
  color: #FFFFFF;
  line-height: 33px;
  text-align: left;
  font-style: normal;
  position: absolute;
  bottom: 37px;
  left: 260px;

}
</style>