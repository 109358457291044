import axios from 'axios';
import { loginreq, reqpost, reqget } from './index';
export const settingdelete = (params) => { return reqpost("post", "/api/protocol-setting/delete",params) };//新增菜单列表
export const getPCAccountList = (params) => { return reqget("get", "/lockLock/getPCAccountList",params) };//新增菜单列表

// export const highQualityAccountList = (params) => { return reqget("get", "/account/highQualityAccountList",params) };//新增菜单列表


export const getList = (params) => { return reqget("get", "/lockLock/getList",params) };//新增菜单列表
export const bannerList = (params) => { return reqget("get", "/lockLock/bannerList",params) };//新增菜单列表
export const getVideoPlayBackList = (params) => { return reqget("get", "/lockLock/getVideoPlayBackList",params) };//新增菜单列表
export const getTeacherList = (params) => { return reqget("get", "/lockLock/getTeacherList",params) };//新增菜单列表




export const teachergetList = (params) => { return reqget("get", "/teacher/getList",params) };//新增菜单列表
export const WonderfulAtlasList = (params) => { return reqget("get", "/lockLock/WonderfulAtlasList",params) };//新增菜单列表
export const lockLockAccountList = (params) => { return reqget("get", "/lockLock/accountList",params) };//新增菜单列表
export const lockLockgetList = (params) => { return reqget("get", "/lockLock/getList",params) };//查询活动列表
export const getOneByType = (params) => { return reqget("get", "/lockLock/getOneByType",params) };//

export const getWonderfulAtlasDetail = (params) => { return reqget("get", "/lockLock/getWonderfulAtlasDetail",params) };//精彩图集
export const getActivityDetail = (params) => { return reqget("get", "/lockLock/getActivityDetail",params) };//精彩图集
export const getTeacherDetail = (params) => { return reqget("get", "/lockLock/getTeacherDetail",params) };//精彩图集
export const personalData = (params) => { return reqget("get", "/lockLock/personalData",params) };//精彩图集
export const checkSmsCode = (params) => { return reqget("get", "/tencent/sendSms",params) };//精彩图集

export const registerForPC = (params) => { return reqpost("post", "/login/registerForPC",params) };//精彩图集
export const accountDetail = (params) => { return reqget("get", "/account/detail",params) };//精彩图集

export const detailForPC = (params) => { return reqget("get", "/lockLock/detailForPC",params) };//精彩图集


export const loginForPC = (params) => { return reqpost("post", "/login/loginForPC",params) };//精彩图集

export const accountPersonalData = (params) => { return reqget("get", "/account/personalData",params) };//精彩图集
export const guessMyLoveForPC = (params) => { return reqget("get", "/account/guessMyLoveForPC",params) };//精彩图集

export const lookguessMyLoveForPC = (params) => { return reqget("get", "/lockLock/guessMyLoveForPC",params) };//精彩图集
export const fileupload = (params) => { return reqpost("post", "/file/upload",params) };//精彩图集
export const getSelectList = (params) => { return reqget("get", "/common/getSelectList",params) };//精彩图集


export const getAddressList = (params) => { return reqget("get", "/common/getAddressList",params) };//精彩图集
export const getOccupationSelectList = (params) => { return reqget("get", "/common/getOccupationSelectList",params) };//精彩图集


export const accountEdit = (params) => { return reqpost("post", "/account/newEdit",params) };//精彩图集
export const updateMyIntroduction = (params) => { return reqpost("post", "/account/updateMyIntroduction",params) };//精彩图集
export const inviteImproveInformation = (params) => { return reqpost("post", "/account/inviteImproveInformation",params) };//精彩图集
export const getMateSelection = (params) => { return reqpost("post", "/mate-selection/getMateSelectionForPC",params) };//精彩图集




export const selectionedit = (params) => { return reqpost("post", "/mate-selection/edit",params) };//精彩图集

export const editHeadIcon = (params) => { return reqpost("post", "/account/editHeadIcon",params) };//精彩图集
export const stringscreate = (params) => { return reqpost("post", "/pull-strings/create",params) };//精彩图集



export const highQualityAccountList = (params) => { return reqget("get", "/account/highQualityAccountList",params) };//精彩图集

export const getNameAndHeadStatusFroPC = (params) => { return reqget("get", "/account/getNameAndHeadStatusFroPC",params) };//精彩图集


export const activityGetList = (params) => { return reqget("get", "/activity/getList",params) };//精彩图集



export const listByMiniApp = (params) => { return reqget("get", "/banner/listByMiniApp",params) };//精彩图集
export const atlaslistByMiniApp = (params) => { return reqget("get", "/wonderful-atlas/listByMiniApp",params) };//精彩图集



export const getAddressListForPC = (params) => { return reqget("get", "/common/getAddressListForPC",params) };//精彩图集

// export const getAddressList = (params) => { return reqget("get", "/common/getAddressList",params) };//精彩图集



























