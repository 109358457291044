<template @scroll.native="getScroll">
	<!-- Navbar start -->

	<div>

		<nav :style="isShallow == 2 ? 'background-color:#000; width:100%; transform: translateY(0%)!important;   transition: all .5s ease-out;' : 'transition: all 1s ease-out;width:100%; transform: translateY(0%)!important;'"
			class="w-screen fixed bg-opacity-0  p-4 lg:px-50 flex justify-between z-50">
			<!-- Logo start -->
			<div class="h-full flex flex-col justify-center">
				<img style="width: 120px; height: 50px; margin-left: 200px;" src="../assets/logo@2x.png" alt="">
			</div>
			<button role="menubar" class="md:hidden">
				<i class="fa-solid fa-bars text-2xl"></i>
			</button>
			<!-- Toggler end -->

			<!-- Menu content start -->
			<div style="margin-right: 270px;" v-if="isShallow==2">
				<v-sheet color="transparent">
					<template v-slot:extension></template>
					<v-tabs v-model="tab" :items="tabs" align-tabs="center"   color="deep-purple-accent-2" height="60">
						<template v-slot:tab="{ item }">
							<v-tab  @click="curText(item)" :text="item.text" :value="item.ifindex"
							style="color: #fff;"  class="text-none"></v-tab>
						</template>
					</v-tabs>
				</v-sheet>
				<v-btn v-if="!token&&isShallow!=2" style="position: absolute; top:25px; right: 160px; width: 110px;
height: 38px;
border: 1px solid #000;font-family: PingFangSC, PingFang SC;
font-weight: 500;
font-size: 18px;
color: #000;
line-height: 38px;
text-align: center;
border: 1px solid #000;
font-style: normal;" @click="gotoClick">
					登录
				</v-btn>
				<v-btn v-if="!token&&isShallow==2" style="position: absolute; top:25px; right: 160px; width: 110px;
height: 38px;
border: 1px solid #A6A6A6;font-family: PingFangSC, PingFang SC;
font-weight: 500;
font-size: 18px;
color: #FFFFFF;
line-height: 38px;
text-align: center;
border: 1px solid #fff;
font-style: normal;" @click="gotoClick">
					登录
				</v-btn>

			</div>


			
			<div style="margin-right: 270px;"v-if="isShallow!=2" >
				<v-sheet color="transparent">
					<template v-slot:extension></template>
					<v-tabs v-model="tab" :items="tabs" align-tabs="center" :color="isShallow != 1?'#783DF9':'#783DF9'" height="60">
						<template v-slot:tab="{ item }">
							<v-tab @click="curText(item)" :text="item.text" :value="item.ifindex"
							class="text-none"></v-tab>
						</template>
					</v-tabs>
				</v-sheet>
				<v-btn v-if="!token&&isShallow!=2" style="position: absolute; top:25px; right: 160px; width: 110px;
height: 38px;
border: 1px solid #000;font-family: PingFangSC, PingFang SC;
font-weight: 500;
font-size: 18px;
color: #000;
line-height: 38px;
text-align: center;
border: 1px solid #000;
font-style: normal;" @click="gotoClick">
					登录
				</v-btn>
				<v-btn v-if="!token&&isShallow==2" style="position: absolute; top:25px; right: 160px; width: 110px;
height: 38px;
border: 1px solid #A6A6A6;font-family: PingFangSC, PingFang SC;
font-weight: 500;
font-size: 18px;
color: #FFFFFF;
line-height: 38px;
text-align: center;
border: 1px solid #fff;
font-style: normal;" @click="gotoClick">
					登录
				</v-btn>

			</div>
			<div v-if="token" class="addwex" @click="onClikc">
				<img :src="formlists.headIcon" alt="">
				<div class="addwex-xlk" v-if="formlists.headIconStatus==1">审核中</div>
				<div class="addwex-xlk" v-if="formlists.headIconStatus==3">审核失败</div>
				<div class="addwex-xlekk">{{ formlists.nickNameStatus!=2?'***': formlists.nickName }}</div>
				<div class="addwex-xlekkx">UID:{{ userlist.id }}</div>
			</div>
			<div class="xlekkx-xler"  v-if="ifshowm">
				<v-btn variant="text" class="xlekkx-xlerchild" @click="onBnCLK(1)">编辑个人主页</v-btn>
				<v-btn variant="text" class="xlekkx-xlerchild" @click="onBnCLK(2)">退出登录</v-btn>
				<!-- <div  @click='onBnClk(2)'></div> -->
				<img src="https://online-miniapp-1304146125.cos.ap-beijing.myqcloud.com/d3eb37a2-90dc-4108-90a8-7b6b3e0cac82.png"
					alt="">
			</div>

		</nav>
		<!-- Navbar end -->
	</div>

</template>

<script>
// import { isShallow } from 'vue';
import {getNameAndHeadStatusFroPC} from '../axios/dev.js'
import HomeView from '@/HomeView.vue';
import router from "../router/router"

import FingerprintJS from '@fingerprintjs/fingerprintjs' // 引入s
import { useRoute } from 'vue-router';



export default {
	data() {
		return {
			tab: 0,
			ilfser: '',
			userlist: '',
			token: '',
			ifshowm: false,
			items: [
				{ title: 'Click Me' },
				{ title: 'Click Me' },
				{ title: 'Click Me' },
				{ title: 'Click Me 2' },
			],
			more: [
				'News', 'Maps', 'Books', 'Flights', 'Apps',
			],
			currentItem: 'tab-Web',

			more: [
				'News', 'Maps', 'Books', 'Flights', 'Apps',
			],
			formlists:'',

			tabs: [
				{
					icon: 'mdi-book-open-page-variant',
					text: '首页',
					value: 'tab-1',
					ifindex: 0
				},
				{
					icon: 'mdi-handshake-outline',
					text: '会员展示',
					value: 'tab-2',
					ifindex: 1
				},
				{
					icon: 'mdi-license',
					text: '单身联谊',
					value: 'tab-3',
					ifindex: 2
				},
				{
					icon: 'mdi-shield-lock-outline',
					text: '婚恋服务',
					value: 'tab-4',
					ifindex: 3
				},
				{
					icon: 'mdi-shield-lock-outline',
					text: '形象打造',
					value: 'tab-5',
					ifindex: 4
				},
				{
					icon: 'mdi-shield-lock-outline',
					text: '脱单故事',
					value: 'tab-6',
					ifindex: 5
				},

				{
					icon: 'mdi-shield-lock-outline',
					text: '月娘资讯',
					value: 'tab-7',
					ifindex: 6
				},
				{
					icon: 'mdi-shield-lock-outline',
					text: '关于月娘',
					value: 'tab-8',
					ifindex: 7
				},
			],



			isShallow: "1"

		}
	},
	components: {
		HomeView
	},
	// 监听localStorage中键名为tableData的变化
	
	created() {
		
     this.getvisitorId()
		this.userlist = JSON.parse(localStorage.getItem("userlist"));
		this.token = localStorage.getItem('token')
		const route = useRoute();
 

		this.tab = localStorage.getItem('ifNum')
		if (route.name == 'index') {
			this.tab = 0
		}
		if(localStorage.getItem('token')){
			getNameAndHeadStatusFroPC().then(res=>{
				this.formlists=res.data
			})
		}
	},
	watch: {
		$route(to, from) {
			router.go(0)
			// this.ifshowm = false,
		},
	},

	methods: {
		async getvisitorId() {
      const fp = await FingerprintJS.load()
      const result = await fp.get()
      const visitorId = result.visitorId

	  localStorage.setItem('deviceId', visitorId);
    //   console.log('唯一标识', visitorId)
    },
		onBnCLK(i) {
			if (i == 2) {
				this.ifshowm = false,
				localStorage.removeItem("token");
				localStorage.removeItem("userlist");
				router.push('/')
				router.go(0)
			} else {
				this.ifshowm = false,
					router.push('/PersonalData')

			}
		},
		onClikc() {
			this.ifshowm = !this.ifshowm
		},

		handleMouseLeave() {


		},
		handleMouseEnter(item) {
			this.ifshowm = true
		},
		gotoClick() {
			router.push('/login')


		},
		curText(e) {
			localStorage.setItem('ifNum', e.ifindex);
			if (e.ifindex == 1) {
				router.push('/Home')
			} else if (e.ifindex == 0) {
				router.push('/')
			} else if (e.ifindex == 2) {
				router.push('/Fellowship')
			} else if (e.ifindex == 5) {
				router.push('/singleStoryPlus')

			} else if (e.ifindex == 6) {

				router.push('/inforMation')
			} else if (e.ifindex == 4) {

				router.push('/ImageBuilding')
			} else if (e.ifindex == 3) {
				router.push('/Matchmaking')
			} else if (e.ifindex == 7) {
				router.push('/concerningYn')
			}
			// console.log(e);
		},
		handleScroll() {
			if (window.pageYOffset > 100) {
				this.isShallow = 2
			} else {
				this.isShallow = 1
			}
		},
		addItem(item) {
			const removed = this.items.splice(0, 1)
			this.items.push(
				...this.more.splice(this.more.indexOf(item), 1),
			)
			this.more.push(...removed)
			this.$nextTick(() => { this.currentItem = 'tab-' + item })
		},
		navToggle() {
			let menu = document.querySelector("[role='menu']");
			let isExpanded = menu.getAttribute('aria-expanded');
			menu.setAttribute('aria-expanded', !isExpanded);
			menu.classList.toggle('hidden');
			menu.classList.toggle('flex');
		}
	},
	mounted() {
		// console.log('111111111111111');
		window.addEventListener("scroll", this.handleScroll);
	}
}
</script>

<style scoped>
.addwe {
	position: absolute;
	top: 25px;
	right: 160px;
	width: 110px;
	height: 38px;
	border-radius: 2px;
	border: 1px solid #A6A6A6;
	font-family: PingFangSC, PingFang SC;
	font-weight: 400;
	font-size: 17px;
	color: #FFFFFF;
	line-height: 38px;
	text-align: center;
	border: 1px solid #fff;
	font-style: normal;
	position: relative;
	
}

.addwex {
	position: absolute;
	top: 25px;
	right: 160px;
	width: 77px;
	height: 38px;
	/* background-color: saddlebrown; */
	/* position: relative; */
}

.addwex img {
	width: 38px;
	height: 38px;
	border-radius: 50%;
	position: relative;
}
.addwex-xlk{
	width: 38px;
	height: 38px;
	border-radius: 50%;
	text-align: center;
	line-height: 38px;
	position: absolute;
	top:0;
	left: 0;
	background-color: #000;
	opacity: 0.6;
	font-size: 8px;
	color: #fff;

}
.addwex-xlekk {
	font-family: PingFangSC, PingFang SC;
	font-weight: 500;
	font-size: 14px;
	color: #FFFFFF;
	text-shadow: 2px 2px 2px #000;
	font-style: normal;
	position: absolute;
	top: 0px;
	left: 60%;
	width: 200px;
	height: 14px;
}

.addwex-xlekkx {
	font-family: PingFangSC, PingFang SC;
	font-weight: 400;
	font-size: 14px;
	color: #FFFFFF;
	text-shadow: 2px 2px 2px #000;
	font-style: normal;
	position: absolute;
	top: 20px;
	left: 60%;
	width: 200px;
	height: 14px;
}

.xlekkx-xler {
	width: 150px;
	height: 100px;
	background: #FFFFFF;
	border-radius: 10px;
	display: flex;
	position: absolute;
	top: 80px;
	flex-direction: column;
	right: 120px;
	transform: translateY(0%) !important;
	transition: all .5s ease-out;

}

.xlekkx-xlerchild {
	flex: 1;
	height: 50px;
	line-height: 50px;
	text-align: center;
	font-family: PingFangSC, PingFang SC;
	font-weight: 400;
	font-size: 18px;
	color: #333333;
	font-style: normal;
}

.xlekkx-xler img {
	width: 32px;
	height: 12px;
	position: absolute;
	top: -10px;
	left: 59px;
}

.text-none {
	font-size: 18px;
}
</style>