import axios from 'axios';
// import { Message } from "element-ui";
import router from "../router/router"


// 登录请求方法
const loginreq = (method, url, params) => {
    console.log(url);
    return axios({
        method: method,
        url: url,
        headers: {
          
        },
        data: params,

        traditional: true,

        transformRequest: [
            function(data) {
                let ret = ''
                for (let it in data) {
                    ret +=
                        encodeURIComponent(it) +
                        '=' +
                        encodeURIComponent(data[it]) +
                        '&'
                }
                return ret
            }
        ]
    }).then(res => res.data);
};
// const urls = 'https://miniapp.yuenianghl.com'//测试环境
const urls = 'https://miniapp-online.yuenianghl.com'//线上环境
// const urls ='http://192.168.20.39:10000/';//本地地址

// 通用公用方法
const reqpost = (method, url, params) => {
    // let ther =this
    return axios({
        method: method,
        url: urls+url,
        headers: {
          'platformType':3,
            'Content-Type': 'application/json',
            'token':localStorage.getItem('token')
        },
        data:params,
        // traditional: true,
    }).then(res=>{
        if(res.data.code==999){
        
              localStorage.removeItem("token");
              localStorage.removeItem("userlist");
              router.push('/')
     
            return
          }else if(res.data.code==0){
            return res.data
          }else{
            return res.data
            // Message({
            //     type: "warning",
            //     message: res.data.message
            //   });
           
          }
    });

    
};
const reqget = (method, url, params) => {
    // let ther =this
    return axios({
        method: method,
        url: urls+url,
        headers: {
              'platformType':3,
            'Content-Type': 'application/json',
            
            'token':localStorage.getItem('token')
        },
        params,
        // traditional: true,
    }).then(res=>{
        if(res.data.code==999){
          
              localStorage.removeItem("token");
              localStorage.removeItem("userlist");
              router.push('/')
            returns
          
          }else if(res.data.code==0){
            return res.data
          }else{
            return res
            // Message({
            //     type: "warning",
            //     message: res.data.message
            //   });
           
          }
    });

    
};

export {
    loginreq,
    reqget,

    reqpost
}