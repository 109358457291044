<template>
    <transition  mode="in-out || out-in"name="fade" > 
       <section >
     <div class="AboutSection">
            <div class="AboutSection-one">高端红娘服务</div>
            <div class="AboutSection-Single">High end matchmaker service</div>
            <div class="AboutSection-two">月娘婚恋一对一高端婚恋，为您量身定制高品质婚恋服务</div>

               <div class="AboutSection-p" >
               <div class="AboutSection-xian"  @mouseenter.stop="handleMouseEnter(1)" @mouseleave.stop="handleMouseLeave(1)">
                <div class="AboutSection-chuldx">
                    <img :style="oneif?'width: 96%;transform: :translate(30px, 0);  border-radius: 10px 0 0 10px;   transition: all 1s ease-out;  opacity: 1!important;  box-shadow: 10px 8px 1px 1px #BEBBBB;':' width: 100%;transition: all .5s ease-out; '" class="AboutSection-img" src="https://online-miniapp-1304146125.cos.ap-beijing.myqcloud.com/0bccaf0d-d2bd-42ab-80ac-3859e5ee64a5.png" alt="">
                    <div :style="oneif?'transform: :translate(30px, 0); transition: all 1s ease-out;  opacity: 1!important;  box-shadow: 2px 3px 2px 0px #BEBBBB; width: 20px;':'transition: all .5s ease-out;width: 0px;'"  class="AboutSection-right"></div>
                </div>
                <div class="AboutSection-chuldx" >
                    <img class="AboutSectionx-ims"  :src="oneif?'https://test-cos.ynhlminiapp.com/4fa66c80-4e2d-4c1f-a275-d1b309f5d691.png':'https://test-cos.ynhlminiapp.com/f481aaef-3ae4-4b13-b1c7-bf7cbd6f8cbf.png'" alt="">
                     <span :style="oneif?'color: #783DF9;':' color: #051850; '">线下门店全直营</span>
                     <div  class="AboutSectionx-xer">坚持实体婚恋店铺，打造一线品质服务</div>
                     <img src="../assets/home/zouj.png" alt="">
                </div>
               </div>
               <div class="AboutSection-xian"  @mouseenter.stop="handleMouseEnter(3)" @mouseleave.stop="handleMouseLeave(3)">
                <div class="AboutSection-chuldx">
                    <img class="AboutSectionx-ims"  :src="twos?'https://test-cos.ynhlminiapp.com/090a60b8-95f1-4cf0-850f-fa2d39f1c3e4.png':'https://online-miniapp-1304146125.cos.ap-beijing.myqcloud.com/142a3b41-6e6b-421d-b6a6-708b6732feac.png'" alt="">
                     <span  :style="twos?'color: #783DF9;':' color: #051850; '">个人隐私有安全保障</span>
                     <div   class="AboutSectionx-xer">专属红娘多重认证资料，为您建立专属个人私密征婚档案，严格保密，保障客户信息不被泄漏</div>
                     <img  style="float: right; margin-right: 70px;" src="https://test-cos.ynhlminiapp.com/1d84ca01-3812-43bb-b0eb-8eca5a5fa5a2.png" alt="">
                </div>
                <div class="AboutSection-chuldx">
                    <img :style="twos?'width: 96%;transform: :translate(180px, 0);border-radius: 0px 10px  10px 0px;    transition: all 1s ease-out;  opacity: 1!important;  box-shadow: 10px 8px 1px 1px #BEBBBB;':' width: 100%;transition: all .5s ease-out; '" class="AboutSection-img" src="https://online-miniapp-1304146125.cos.ap-beijing.myqcloud.com/67fa9d5f-2b60-4e6f-bbde-712cb810692a.png" alt="">
                    <div :style="twos?'transform: :translate(30px, 0); transition: all 1s ease-out;  opacity: 1!important;   width: 20px;':'transition: all .5s ease-out;width: 0px;'" style="position: absolute; left: 0;border-radius: 7px 0px 0px 7px;"  class="AboutSection-right"></div>
                </div>
                
               </div>
               <div class="AboutSection-xian"  @mouseenter.stop="handleMouseEnter(4)" @mouseleave.stop="handleMouseLeave(4)">
                <div class="AboutSection-chuldx">
                    <img :style="forns?'width: 96%;transform: :translate(30px, 0);border-radius: 10px 0 0 10px;   transition: all 1s ease-out;  opacity: 1!important;  box-shadow: 10px 8px 1px 1px #BEBBBB;':' width: 100%;transition: all .5s ease-out; '" class="AboutSection-img" src="https://online-miniapp-1304146125.cos.ap-beijing.myqcloud.com/9a7fe1c5-7064-4cad-9763-04e29447fbf6.png" alt="">
                    <div :style="forns?'transform: :translate(30px, 0); transition: all 1s ease-out;  opacity: 1!important;  box-shadow: 2px 3px 2px 0px #BEBBBB; width: 20px;':'transition: all .5s ease-out;width: 0px;'"  class="AboutSection-right"></div>
                </div>
                <div class="AboutSection-chuldx" >
                    <img class="AboutSectionx-ims"  :src="forns?'https://test-cos.ynhlminiapp.com/bab395b4-d227-4327-9a62-168e288430bc.png':'https://test-cos.ynhlminiapp.com/78157617-0138-4097-a0a5-c7d667ea06bc.png'" alt="">
                     <span :style="forns?'color: #783DF9;':' color: #051850; '">甄选匹配会员</span>
                     <div  class="AboutSectionx-xer">根据您的择偶需求,多重渠道为您甄选以严肃婚姻为目的的精英单身会员,为您精挑细选,高净值的TA</div>
                     <img src="../assets/home/zouj.png" alt="">
                </div>
               </div>
               <div class="AboutSection-xian"  @mouseenter.stop="handleMouseEnter(6)" @mouseleave.stop="handleMouseLeave(6)">
                <div class="AboutSection-chuldx">
                    <img class="AboutSectionx-ims"  :src="Sixs?'https://test-cos.ynhlminiapp.com/802481c4-fdc8-4f5a-8ef5-8fc2246f7eb2.png':'https://online-miniapp-1304146125.cos.ap-beijing.myqcloud.com/96a635ea-cbf2-4765-a75c-d946288cf977.png'" alt="">
                     <span  :style="Sixs?'color: #783DF9;':' color: #051850; '">浪漫约见</span>
                     <div   class="AboutSectionx-xer">专业服务红娘老师“牵线搭桥”，“恋爱跟踪”为您安排安全浪漫的约见</div>
                     <img  style="float: right; margin-right: 70px;" src="https://test-cos.ynhlminiapp.com/1d84ca01-3812-43bb-b0eb-8eca5a5fa5a2.png" alt="">
                </div>
                <div class="AboutSection-chuldx">
                    <img :style="Sixs?'width: 96%;transform::translate(180px, 0);border-radius: 10px 0 0 10px; margin-left: 3%;   transition: all 1s ease-out;  opacity: 1!important;  box-shadow: 10px 8px 1px 1px #BEBBBB;':' width: 100%;transition: all .5s ease-out; '" class="AboutSection-img" src="https://online-miniapp-1304146125.cos.ap-beijing.myqcloud.com/be5a2c35-8d5e-433f-9d46-28f3e7f58dee.png" alt="">
                    <div :style="Sixs?'transform: :translate(30px, 0); transition: all 1s ease-out;  opacity: 1!important;   width: 20px;':'transition: all .5s ease-out;width: 0px;'" style="position: absolute; left: 0;border-radius: 7px 0px 0px 7px;"  class="AboutSection-right"></div>
                </div>
                
               </div>
               <div class="AboutSection-rig"  @mouseenter.stop="handleMouseEnter(2)" @mouseleave.stop="handleMouseLeave(2)">
                <div class="AboutSection-ghncl">
                    <img :style="zhif?'transform: :translate(30px, 0);  border-radius: 10px 10px 0px 0px;   height: 95%;  transition: all 1s ease-out;  opacity: 1!important;  box-shadow: 10px 8px 1px 1px #BEBBBB;':'   height: 100%; transition: all .5s ease-out; '" class="AboutSection-imgx" src="https://online-miniapp-1304146125.cos.ap-beijing.myqcloud.com/5b9fa7f5-7428-4612-8080-517146180690.png" alt="">
                    <div :style="zhif?'transform: :translate(30px, 0); transition: all 1s ease-out;  opacity: 1!important;  box-shadow: 2px 3px 2px 0px #BEBBBB; height: 20px;':'transition: all .5s ease-out;width: 0px;'"  class="AboutSection-rightx"></div>
                </div>
                <div class="AboutSection-chuldx" >
                    <img class="AboutSectionx-ims"  :src="zhif?'https://test-cos.ynhlminiapp.com/14f0da26-374f-49e3-af50-9558390d6ccd.png':'https://test-cos.ynhlminiapp.com/bfe7e1f8-38e6-476c-ac65-97ac97d3f7d9.png'" alt="">
                     <span :style="zhif?'color: #783DF9;':' color: #051850; '">资深红娘一对一</span>
                     <div  class="AboutSectionx-xer">资深红娘团队，全程一对一贴心管家式服务深度陪同，为您的爱情保驾护航</div>
                     <img style="position: absolute; top: 60px; left: 0px;" src="https://test-cos.ynhlminiapp.com/83443242-3912-4806-83f2-ea9f06dbabfb.png" alt="">
                </div>

</div>


<div class="AboutSection-rigxs"  @mouseenter.stop="handleMouseEnter(5)" @mouseleave.stop="handleMouseLeave(5)">
                <div class="AboutSection-ghncl">
                    <img :style="number?'transform: :translate(30px, 0);  border-radius: 10px 10px 0px 0px;   height: 95%;  transition: all 1s ease-out;  opacity: 1!important;  box-shadow: 10px 8px 1px 1px #BEBBBB;':'   height: 100%; transition: all .5s ease-out; '" class="AboutSection-imgx" src="https://online-miniapp-1304146125.cos.ap-beijing.myqcloud.com/d531c956-41c9-4815-a299-0eb5788b95dd.png" alt="">
                    <div :style="number?'transform: :translate(30px, 0); transition: all 1s ease-out;  opacity: 1!important;  box-shadow: 2px 3px 2px 0px #BEBBBB; height: 20px;':'transition: all .5s ease-out;width: 0px;'"  class="AboutSection-rightx"></div>
                </div>
                <div class="AboutSection-chuldx" >
                    <img class="AboutSectionx-ims"  :src="number?'https://test-cos.ynhlminiapp.com/64048921-812b-4788-bb59-580088df0efe.png':'https://test-cos.ynhlminiapp.com/c4431553-b466-47b6-a655-313033c90e92.png'" alt="">
                     <span :style="number?'color: #783DF9;':' color: #051850; '">全程跟踪服务</span>
                     <div  class="AboutSectionx-xer">从“精准匹配”、“情感服务”、“婚恋服务”、“形象打造”多维度，全程陪伴式高品质服务，为你解决遇到的各种问题</div>
                     <img style="position: absolute; top: 60px; left: 0px;" src="https://test-cos.ynhlminiapp.com/83443242-3912-4806-83f2-ea9f06dbabfb.png" alt="">
                </div>

</div>

              
           </div>

           
     </div>
     
   </section>
    </transition>
   <!-- About section start -->
   
   <!-- About section end -->
</template>

<script>
  import { add } from "../utils/deta.js";
import {getList }from '../axios/dev'
export default {
   data(){
       return{
        oneif:false,
        twos:false,
        zhif:false,
        forns:false,
        Sixs:false,
        number:false,
        
           testShowxr:false,

formlist:{
    url:'',
    show:false,
    
},



       }
   },


   mounted(){
    //   // this.add()
    //   getList({
    //      current:1,
    //      size:4,
        
    //      searchType:1,
    //   }).then(res=>{
    //      this.formlist=res.data.list
    //       console.log(this.formlist);
          
    //    })
   },
   methods:{


// timestampToTime


// 时间戳转换函数


// 示例使用
// 输出：2021-05-26 16:11:25

       handleMouseEnter(item) {
        if(item==1){
            this.oneif=true
        }else if(item==4){
            this.forns=true
        }else if(item==3){
            this.twos=true
        }else if(item==6){
            this.Sixs=true
        }else if(item==2){
            this.zhif=true
        }else if(item==5){
            this.number=true
        }
            
        },
         
   
   handleMouseLeave(item) {
    if(item==1){
        this.oneif=false
    }else if(item==4){
            this.forns=false
        }else if(item==3){
            this.twos=false
   }
   else if(item==6){
            this.Sixs=false
        }
        else if(item==2){
            this.zhif=false
        }else if(item==5){
            this.number=false
        }
   },
}

}
</script>

<style scoped>
.AboutSection{
   width: 100%;
   height: 1832px;
   /* border: 1px solid #ccc; */
 

   margin-top: 50px;
   /* background-color: rebeccapurple; */
}
.AboutSection-one{
   margin: 20px auto; 
   font-family: PingFangSC, PingFang SC;
font-weight: 500;
font-size: 56px;
color: #051850;
line-height: 82px;
text-align: center;
font-style: normal;

}
.AboutSection-Single{
 
font-family: PingFangSC, PingFang SC;
font-weight: 400;
font-size: 24px;
color: rgba(0,0,0,0.1);
margin-top: -35px;
letter-spacing: 6px;
text-align: center;
font-style: normal;
text-transform: uppercase;
}
.AboutSection-two{

   /* margin: 0px auto;  */
   font-family: PingFangSC, PingFang SC;
font-weight: 400;
font-size: 24px;
margin-top: 10px;
/* border: 1px solid #ccc; */
color: #999;
line-height: 33px;
text-align: center;
font-style: normal;


}

.AboutSection-p{
   width: 76%;
   height: 1532px;
   /* border: 1px solid #051850; */
   /* background-color: rebeccapurple; */
   margin-top: 70px;
   margin-left: 12%;
   position: relative;

}
.AboutSection-xian{
    width: 66%;
    height: 383px;
    /* border: 1px solid #051850;s */
    display: flex;

}
.AboutSection-chuldx{
    flex: 1;
    /* border: 1px solid #ccc; */
    text-align: center;
    position: relative;
}
.AboutSection-chuldx .AboutSection-img{
   
    height: 100%;
    border-radius: 10px;
}
.AboutSection-imgx{
    width: 100%;
   
    border-radius: 10px;

}
.AboutSection-right{
    width: 20px;
    height: 100%;
    background: #783DF9;
border-radius: 0px 7px 7px 0px;
position: absolute;
right: 0;
top: 0;
}
.AboutSection-rightx{
    width: 100%;
    height:20px;
    background: #783DF9;
    border-radius: 0px 7px 7px 0px;

}

.AboutSectionx-ims{
    width: 71px;
    height: 70px;
    position: absolute;
    top: 99px;
    left: 67px;

}
.AboutSection-chuldx span:nth-child(2){
    position: absolute;
    top: 116px;
    left: 147px;
    font-family: PingFangSC, PingFang SC;
font-weight: 600;
font-size: 26px;
/* color: #783DF9; */
line-height: 37px;
font-style: normal;
}
.AboutSectionx-xer{
    width: 310px;
  
    margin-top: 177px;
    margin-left: 67px;
    font-family: PingFangSC, PingFang SC;
font-weight: 400;
font-size: 16px;
color: #666666;
line-height: 24px;
text-align: left;
font-style: normal;
}
.AboutSection-chuldx img:nth-child(4){
    width: 30px;
    height: 30px;
    margin-left: 72px;
    margin-top: 5px;
   
    /* position: absolute;
    left: 72px;
    top: 211px; */
}
.AboutSection-rig{
    width: 32%;
    position: absolute;
    top: 0;
    right: 0;
    height: 766px;
    display: flex;
    flex-direction: column;
}
.AboutSection-rigxs{
    width: 32%;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 766px;
    display: flex;
    flex-direction: column;
}
.AboutSection-ghncl{
    /* border: 1px solid red; */
    flex: 1;
}
</style>