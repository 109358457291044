
import App from './App.vue'
import router from "./router/router"
import colors from 'vuetify/util/colors'
import { createApp } from 'vue'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
// import dispatchEventStroage from './utils/watchLocalStorage'


import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/dist/vuetify.min.css' 
import '@/style.css'
// import vue3SeamlessScroll  from "vue3-seamless-scroll";
const vuetify = createVuetify({
  theme: {
    primary: colors.indigo.base, // #E53935
    secondary: colors.indigo.base, // #FFCDD2
    accent: colors.indigo.base // #3F51B5
  },
  components,
  directives,
 
})

const app = createApp(App)
app.use(router)
// app.use(vue3SeamlessScroll)
app.use(ElementPlus)
// app.use(dispatchEventStroage);
app.use(vuetify)
app.mount('#app')
new Vue({
  el: '#app',
  router,
  vuetify:new Vuetify,//这一句是关键
  template: '<App/>'
})







