<template>
	<div class="active">
		    <!-- Navbar start -->
			
		<!-- <section style="width:100%;"> -->
		

		<video style="width: 100%;" muted autoplay="autoplay" loop="loop">
	<source   src="https://online-miniapp-1304146125.cos.ap-beijing.myqcloud.com/097bf2cd-c534-4462-b735-c316f8118689.mp4" type="video/mp4"/>
</video>
<div class="adders-shubiao"> <img src="../assets/shubiao.gif" alt=""></div>

	<div id="adders">
	
	<div class="adders-al" v-if="!token">
		<v-form ref="form" class="adders-al" style="width: 100%; margin-left: 0px; margin-top: 0;">
			<div class="adders-child">
		<span>我的性别</span>
		<div class="adders-group">
			<v-radio-group v-model="sex"  :rules="[v => !!v || '请选择你的性别']" inline>
	  <v-radio  color="#783DF9" label="男生" value="1"></v-radio>
	  &nbsp; &nbsp; &nbsp;
	  <v-radio  color="#783DF9" label="女生" value="2"></v-radio>
	</v-radio-group>
	
		</div>
	</div>
	<div class="adders-child">
		<span>出生日期</span>
		<div class="adders-group" style="margin-top:-8px;">
			<select style="width: 80px; border: 2px solid #ccc; border-radius: 8px; height: 50px;  "
                                    v-model="selectedYear" @change="updateMonths">
                                    <option style="text-align: center;  " v-for="year in years" :key="year" :value="year">
                                        {{ year + '年' }}</option>
                                </select>
                                <select  style="width: 80px; border: 2px solid #ccc; border-radius: 8px; margin-left: 20px; height: 50px;  "
                                    v-model="selectedMonth" @change="updateDays">
                                    <option  style="text-align: center;" v-for="month in months"
                                        :key="month" :value="month">{{ month + '月' }}</option>
                                </select>
                                <select style="width: 80px; border: 2px solid #ccc; border-radius: 8px;  margin-left: 20px; height: 50px; "
                                    v-model="selectedDay">
                                    <option style="text-align: center;" v-for="day in days" :key="day" :value="day">{{
                                        day +'日'}}</option>
                                </select>
		</div>
	</div>
	<div class="adders-child">
		<span>婚姻状况</span>
		<div class="adders-group">
			<v-radio-group :rules="[v => !!v || '请选择你的婚姻状况']"  v-model="maritalStatus" inline>
	  <v-radio  color="#783DF9" label="未婚" value="1"></v-radio>
	  &nbsp; &nbsp; &nbsp;
	  <v-radio  color="#783DF9" label="离异" value="2"></v-radio>  &nbsp; &nbsp; &nbsp;
	  <v-radio  color="#783DF9" label="丧偶" value="3"></v-radio>
	</v-radio-group>
	
		</div>
	</div>
		
		</v-form>
	
	
	
	<div class="adders-btn" @click="onClickder">免费注册</div>
	</div>

	<div v-if="token">
		<v-img
                  class="adders-mgse"
                lazy-src="https://online-miniapp-1304146125.cos.ap-beijing.myqcloud.com/2228d023-d4de-4722-81dd-969c2b45de28.png"
                src="https://online-miniapp-1304146125.cos.ap-beijing.myqcloud.com/2228d023-d4de-4722-81dd-969c2b45de28.png"
                cover
              ></v-img>

	<div class="adders-xleo">扫码月娘婚恋小程序开启缘分</div>
	</div>
	<img  class="adders-img" src="../assets/login.gif" alt="">
	
	
	
	</div>
	</div>
</template>

<script>
 import router from "../router/router"
export default {
	data(){
		return{
			token:'',
			selectedYear: new Date().getFullYear() - 34, // 默认选择当前年份  
            selectedMonth: '1', // 注意月份是从 0 开始的，所以需要 +1  
            selectedDay: '1',
            years: [], // 初始化年份数组（你可以根据需要填充这个数组）  
            months: [], // 初始化月份数组（始终为 1-12）  
            days: [], // 根据年份和月份动态生成的日期数组  
			tab: null,
			sex:"",
			maritalStatus:'',
			text: 'center',
			radios:""
		}
	},
	created() {
		this.token = localStorage.getItem('token')
        // 填充年份数组（例如：过去 5 年到未来 5 年）  
        const currentYear = new Date().getFullYear();
        for (let i = -85; i <= -20; i++) {
            this.years.push(currentYear + i);
        }
        // 初始化月份数组  
        for (let i = 1; i <= 12; i++) {
            this.months.push(i);
        }
        // 初始化日期数组（根据当前年份和月份）  
        this.updateDays();
    },
	methods:{

		async onClickder() {
          
            const { valid } = await this.$refs.form.validate()
            if (valid) {
				router.push({
           path: '/YNenroll',
           query: {
			sex: this.sex,
			maritalStatus:this.maritalStatus,
			selectedYear:this.selectedYear,
			selectedMonth:this.selectedMonth,
			selectedDay:this.selectedDay
           }
            })

            }
        },
	
		updateMonths() {
            // 在这里，你可以重置 days 数组，因为月份已经改变  
            // this.days = []; // 清空日期数组  
        },
		updateDays() {
          
		//   console.log(this.selectedMonth);
		  // 根据选择的年份和月份生成日期数组  
		  const date = new Date(this.selectedYear, this.selectedMonth - 1, 1); // 注意月份需要 -1  
		  const lastDay = new Date(this.selectedYear, this.selectedMonth, 0).getDate(); // 获取该月的最后一天  
		  this.days = Array.from({ length: lastDay }, (_, i) => i + 1); // 生成日期数组  
	  },
	}


}
</script>

<style scoped >
.active{
	width: 100%;

}
#adders{
	width: 460px;
	height: 550px;
	position: absolute;
	top: 204px;
	right: 155px;
	/* position: relative; */
      background:url('https://online-miniapp-1304146125.cos.ap-beijing.myqcloud.com/77ebf0a8-30f0-4636-aafd-7c06077564d5.png') no-repeat center 0px ;
	  background-position: center 0px;
    background-size: cover;

	border-radius: 30px;
}

/* .adders-x{
	position: absolute;
	top: 5px;
	left: 80px;
} */
.adders-al{
	width: 100%;
	height: 250px;
	/* background-color: red; */
	margin-top: 200px;
	display: flex;
	flex-direction: column;
}
.adders-child{
flex: 1;
margin-top: 20px;
/* border: 1px solid #222222; */
position: relative;

}
.adders-child span {
	font-family: PingFangSC, PingFang SC;
font-weight: 500;
font-size: 18px;
color: #222222;
position: absolute;
top: 15px;
left: 50px;
}
.adders-group{
	position: absolute;
	top: 10px;
	left: 140px;
}
.adders-btn{
	width: 380px;
height: 52px;
background: #783DF9;
border-radius: 10px;
position: absolute;
left: 40px;
bottom: 30px;
text-align: center;
line-height: 50px;
font-family: PingFangSC, PingFang SC;
font-weight: 600;
font-size: 16px;
color: #FFFFFF;
}
.adders-img{
	width: 251px;
	height: 190px;
	position: absolute;
	top: -100px;
	right: -60px;
}
.adders-shubiao{
	width: 56px;
	height: 110px;
	position: absolute;
	left: 50%;
	bottom: 0px;
}
.adders-mgse{
	width: 284px;
	height: 284px;
	margin: 180px auto;
	
}
.adders-xleo{
	width: 100%;
	height: 30px;
	text-align: center;
	line-height: 30px;
	position: absolute;
	bottom: 43px;
	left: 0;
	font-family: PingFangSC, PingFang SC;
font-weight: 400;
font-size: 16px;
color: #666666;

font-style: normal;
}
</style>