<template>
    <transition name="fade">
        <section   id="services-section" >
        <!-- Text wrapper start -->
        <v-carousel show-arrows="hover"  progress="primary" cycle height="600" :show-arrows="false">
    <v-carousel-item
      v-for="(item,i) in items"
      :key="i"
       @click="onBnnerlists(item)"
      :src="item.path"
      cover
    ></v-carousel-item>
  </v-carousel>
        <!-- Circle end -->
	</section>
    </transition>
    <!-- Portfolio section start -->
	
	<!-- Portfolio section end -->
</template>

<script>
import {bannerList} from '../axios/dev'
export default {
    data(){
        return{
            items: [
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
          },
        ],
        }
    },
    methods:{
      onBnnerlists(item){
            if(item.url){
              window.location.href = item.url
            }
          },
    },
    created(){
        bannerList({
            current:1,
            size:20,
            addressType:1
        }).then(res=>{
            this.items=res.data
            console.log(res.data,'233333333333333');
        })
    }

}
</script>

<style>
	.fade-enter {
	 
	 opacity:0;
	 
	}
	 
	.fade-leave{
	 
	 opacity:1;
	 
	}
	 
	.fade-enter-active{
	 
	 transition:opacity 1s;
	 
	}
	 
	.fade-leave-active{
	 
	 opacity:0;
	 
	 transition:opacity 1s;
	 
	}

</style>