
// // 导入组件
// import Vue from 'vue';

// import Router from 'vue-router'

import { createRouter,createWebHashHistory } from "vue-router";

const routes = [
 {
  path:'/',
  name: "index",
  meta: {
   keepAlive: true
 },
  component:()=>import("../index.vue")
 },

 {
  path:'/singleStory',
  name: "indexs",
  meta: {
   keepAlive: true
 },
  component:()=>import("../sections/singleStory/Matchmakerteam.vue")
 },
 


 {
  path:'/PrivacyAgreement',
  name: "PrivacyAgreement",
  meta: {
   keepAlive: true
 },
  component:()=>import("../components/PrivacyAgreement.vue")
 },
 {
  path:'/ServiceAgreement',
  name: "ServiceAgreement",
  meta: {
   keepAlive: true
 },
  component:()=>import("../components/ServiceAgreement.vue")
 },
 {
  path:'/Thesinglestory',
  name: "Thesing",
  meta: {
   keepAlive: true
 },
  component:()=>import("../sections/singleStory/Thesinglestory.vue")
 },

 
 
 {
  path:'/concerningYn',
  name: "indexsx",
  meta: {
   keepAlive: true
 },
  component:()=>import("../sections/concerningYn/concerningYn.vue")
 },
 
 {
    path:'/Home',
    name: "Home",
    meta: {
      keepAlive: true
    },
    component:()=>import("../sections/Home")
   },
   {
      path:'/Fellowship',
      name: "Fellowship",
      meta: {
        keepAlive: true
      },
      component:()=>import("../sections/Fellowship")
     },
     {
      path:'/singleStoryPlus',
      name: "singleStoryPlus",
      meta: {
        keepAlive: true
      },
      component:()=>import("../sections/singleStoryPlus")
     },
     {
      path:'/inforMation',
      name: "inforMation",
      meta: {
        keepAlive: true
      },
      component:()=>import("../sections/inforMation")
     },
     {
      path:'/ImageBuilding',
      name: "ImageBuilding",
      meta: {
        keepAlive: true
      },
      component:()=>import("../sections/ImageBuilding")
     },
     {
      path:'/Matchmaking',
      name: "Matchmaking",
      meta: {
        keepAlive: true
      },
      component:()=>import("../sections/Matchmaking")
     },
     {
      path:'/SinglesFellowship',
      name: "SinglesFellowship",
      meta: {
        keepAlive: true
      },
      component:()=>import("../sections/SinglesFellowship/SinglesFellowship.vue")
     },
     {
      path:'/moonDetails',
      name: "moonDetails",
      meta: {
        keepAlive: true
      },
      component:()=>import("../sections/SinglesFellowship/moonDetails.vue")
     },
     {
      path:'/goodLisst',
      name: "goodLisst",
      meta: {
        keepAlive: true
      },
      component:()=>import("../sections/SinglesFellowship/goodLisst.vue")
     },
     {
      path:'/login',
      name: "login",
      meta: {
        keepAlive: true
      },
      component:()=>import("../sections/SinglesFellowship/login.vue")
     },
     {
      path:'/YNenroll',
      name: "YNenroll",
      meta: {
        keepAlive: false
      },
      component:()=>import("../sections/SinglesFellowship/YNenroll.vue")
     },
     {
      path:'/Membershipdetails',
      name: "Membershipdetails",
      meta: {
        keepAlive: true
      },
      component:()=>import("../sections/SinglesFellowship/Membershipdetails.vue")
     },

      {
      path:'/shipdetails',
      name: "shipdetails",
      meta: {
        keepAlive: true
      },
      component:()=>import("../sections/SinglesFellowship/shipdetails.vue")
     },  {
      path:'/PersonalData',
      name: "PersonalData",
      meta: {
        keepAlive: true
      },
      component:()=>import("../sections/SinglesFellowship/PersonalData.vue")
     },
     {
      path:'/SharedComponents',
      name: "SharedComponents",
      meta: {
        keepAlive: false
      },
      component:()=>import("../components/SharedComponents.vue")
     },
     
     
     
     
   
]

const router = createRouter({
  routes,
 history:createWebHashHistory(),


})

router.beforeEach((to, from, next) => {
  // 让页面回到顶部
  document.documentElement.scrollTop = 0;
    next();
});


export default router


