<template>

<div class="active">
<div class="">

  <v-carousel  v-model="model" show-arrows="hover"  progress="primary" cycle height="600" :show-arrows="false">
    <v-carousel-item
      v-for="(item,i) in bannerlists"
      :key="i"
      @click="onBnnerlists(item)"
      :src="item.path"
      cover
    ></v-carousel-item>
  </v-carousel>
     
</div>

    <v-tabs
     style="margin-top: 20px;"
      v-model="tab"
      @click="onClick(tab)"
      align-tabs="center"
      color="#783DF9"
    >
      <v-tab :value="1">海量优质会员</v-tab>
      <v-tab :value="2">优雅女士</v-tab>
      <v-tab :value="3">精致男士</v-tab>
      <v-tab  v-if="!token" :value="4">解锁缘分</v-tab>
      <v-tab :value="5">制作脱单卡片</v-tab>
    </v-tabs>
 
    <v-tabs-window v-model="tab">
    
      <v-tabs-window-item
        v-for="n in 3"
        :key="n"
        :value="n"
      >
      <div class="active-xerrd">
        <div class="active-elr">
        <div  class="active-elrchil"  v-for="item in items" :key="item.id" @click="gotoClick(item.id)">
          <div class="active-elrchilxe">
            <v-img
class="active-elrchilimg" :lazy-src="item.headIcon" 
 :src="item.headIcon" cover></v-img>
          </div>
          <div class="active-elrchilxe">
            <div class="active-xler">
            <span>{{  item.nickNameStatus==2?item.nickName:'***'}}</span>
        
<img  :src="item.sex==1?'https://online-miniapp-1304146125.cos.ap-beijing.myqcloud.com/32be8c62-141d-4363-b675-0f2a959e90dc.png':'https://online-miniapp-1304146125.cos.ap-beijing.myqcloud.com/cd2d6f4e-533a-4c6f-8d15-b909b7f962aa.png'" alt="">
          </div>
          <div class="active-xlcolir">
            {{item.age}}岁 {{ item.maritalStatusDesc }} {{ item.maritalStatusDesc?'|':'|' }} {{item.monthlyIncomeDesc}}{{item.monthlyIncomeDesc?"|":''}}{{ item.educationDesc }}
          </div>
          <img class="active-myqcloud" src="https://online-miniapp-1304146125.cos.ap-beijing.myqcloud.com/34ec67e9-b064-4db3-9ce5-0ff14c704599.png" alt="">
          <div class="active-beijing">
            <img src="https://online-miniapp-1304146125.cos.ap-beijing.myqcloud.com/f104ef23-8219-4c98-a345-32fae60e1147.png" alt="">
            <span>{{item.registerAddress}}</span>
          </div>
          <img  class="active-xwr"  src="https://online-miniapp-1304146125.cos.ap-beijing.myqcloud.com/04d9ee04-6b24-4644-a7fc-b1ad84c2cea4.png" alt="">
          <div class="active-xlerxxew">

          {{ item.introduction }}
          </div>
          </div>
        </div>
       
   
      </div>
      <div class="text-center">
    <v-container>
      <v-row justify="center">
        <v-col cols="8">
          <v-container class="max-width">
            <v-pagination
            @click="btnClick(page)"
              v-model="page"
              :length="listnum"
              color="#783DF9"
              class="my-4"
            ></v-pagination>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
      
      </div>

     
      </v-tabs-window-item>
    </v-tabs-window>

  <Getknow></Getknow>
  <FooterElement></FooterElement>




</div>

</template>

<script>
import router from "../router/router"
import { lockLockAccountList,bannerList,highQualityAccountList } from "../axios/dev";
import Getknow from '@/sections/Getknow.vue';
import FooterElement from '@/components/FooterElement.vue';
import DateSelect from './DateSelect.vue'


export default{
    data(){
        return{
          tab: null,
          page:1,
          model: 0,
          bannerlists:[],
          sex:'',
        listnum:1,
      
      locations: [
        'top',
        'bottom',
        'start',
        'end',
        'center',
      ],
      location: 'end',
            items: [],
            token:'',
   
      search: null,
            indetext:"",
            formDatra:"2017-06-01",
            selectedDate:"",
            form: false,
      email: null,
      password: null,
      loading: false,
      birthday:'',
         
        }
    },
    created(){
     this.token= localStorage.getItem('token')
          bannerList({
            current:1,
            size:20,
            addressType:2
        }).then(res=>{
            this.bannerlists=res.data
           
        })
        },
    mounted(){
      
      this.onAccountList()
        // this.onsettingdelete()
    },
    methods:{
      onBnnerlists(item){
        if(item.url){
          window.location.href = item.url
        }
      },
      gotoClick(item){
        if(localStorage.getItem('token')){
          router.push({
            path: '/Membershipdetails',
            query: {
              id: item
            }
             })

        }else{
          router.push({
            path: '/shipdetails',
            query: {
              id: item
            }
             })
        }

        
       
       
      },
      btnClick(item){

        this.listnum=item

         this.onAccountList()
      },
      onAccountList(){
        if(localStorage.getItem('token')){
          
          highQualityAccountList({
            current:this.listnum,
            size:8,
            sex:this.sex
          
        }).then(res=>{
          let add  = String(res.data.total/8)
          console.log(Math.floor(add));
          if(Math.floor(add) == add){

            this.listnum=add
          }else{
          let adder = Number(add)+1
          this.listnum=adder
        
        
          }
        
            this.items=res.data.list
           
        })

        }else{
          lockLockAccountList({
            current:this.listnum,
            size:8,
            sex:this.sex
          
        }).then(res=>{
          let add  = String( res.data.total/8)
          if(Math.floor(add) === add){
            this.listnum=add
          }else{
          let adder = Number(add)+1
          this.listnum=adder
        
        
          }
        
            this.items=res.data.list
           
        })

        }
       
       
      },
      onClick(item){
        this.listnum=1
        this.page=1

        if(item==2){
          this.sex=2
          this.onAccountList()
        }else if(item==3){
          this.sex=1
          this.onAccountList()
        }
        
        else if(item==4){
          console.log('2222222222');
          router.push({
            path: '/YNenroll',
           
             })
        }else if(item==5){
         
          if(localStorage.getItem('token')){
            router.push({
            path: '/PersonalData',
           
             })
          }else{
            router.push({
            path: '/YNenroll',
           
             })
          }
         
        }
        else{
          this.sex=''
          this.onAccountList()
        }
       
      }
    },
    components:{
      Getknow,
      FooterElement,
        // NavbarElement,
        DateSelect
        
    }
}
</script>





<style scoped >
.active{
    width: 100%;

    height: 1000px;
    /* background-color: #F8F8FA; */
}
.active-elr{
width: 66%;
margin-left: 17%;
display: flex;
/* background-color: red; */
justify-content: space-between;

flex-wrap: wrap;

}
.active-xerrd{
  width: 100%;
  /* height: 1400px; */
  background-color: #F8F8FA;

}

.active-elrchil{
width: 49%;
height: 286px;
margin-top: 20px;
  background: #FFFFFF;
  position: relative;
border-radius: 10px;
display: flex;

}
.active-elrchilxe:nth-child(1){
  width: 260px;

}
.active-elrchilxe{
width: 400px;
  /* border: 1px solid #ddd; */
  /* text-align: center; */
  position: relative;
  
}
.elrchil-xler{
  width: 30%;
  height: 286px;
  background-color: rebeccapurple;
  float: left;
  /* position: relative; */
}
.active-elrchilimg{
  width: 226px;
height: 226px;
border-radius: 12px;
/* margin: ; */
/* padding: 30px; */
margin: 30px;
}


.active-xlcolir{
  width: 100%;
  height: 20px;
  line-height: 20px;
  font-family: PingFangSC, PingFang SC;
font-weight: 400;
font-size: 20px;
margin-left: 15px;
color: #B9811A;


font-style: normal;
  text-align: left;
}
.active-xler{
  width: 280px;
  height: 50px;
margin-top: 40px;
margin-left: 15px;
}
.active-xler span{

  line-height: 50px;
  font-family: PingFangSC, PingFang SC;
font-weight: 500;
font-size: 26px;
color: #333333;
line-height: 37px;
text-align: left;
float: left;
font-style: normal;
}
.active-xler img{
  width: 22px;
  height: 22px;
  float: left;
  margin-top: 12px;
  /* margin-left: 20px; */
}
.active-myqcloud{
  width: 56px;
  height: 50px;
  position: absolute;
  top: 32px;
  right: 30px;
}
.active-beijing{
  width: 100%;
  height: 30px;
  /* line-height: 30px; */
  margin-top: 15px;
  overflow:hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* background-color: darkcyan; */
  position: relative;
}
.active-beijing img{
  width: 16px;
  height: 16px;
position: absolute;
top: 5px;
left: 15px;
 
}
.active-beijing span{
  font-family: PingFangSC, PingFang SC;
font-weight: 400;
font-size: 20px;
color: #999999;
height: 30px;


float: left;
font-style: normal;

position: absolute;
top: 0px;
left: 35px;
}
.active-xwr{
  width: 22px;
  height: 16px;
  position: absolute;
  top: 168px;
  left: 15px;
}
.active-xlerxxew{
 margin-left: 15px;
 margin-top: 40px;
  width: 90%;
height: 56px;
font-family: PingFangSC, PingFang SC;
font-weight: 400;
font-size: 16px;
color: #333333;
line-height: 28px;
text-align: left;
font-style: normal;
overflow:hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
</style>