<template>
    <transition>
       <section >
     <div class="AboutSection">
            <div class="AboutSection-one">单身相亲联谊</div>
            <div class="AboutSection-Single">Single blind date friendship</div>
            <div class="AboutSection-two">名企业名校，线下相亲活动，真实面对面遇见</div>
            <div class="AboutSection-img"> <img @click="onHome" src="../assets/gengd.png" alt=""></div>

           <div  style="margin-top: 80px;">
               <div class="AboutSection-p" >
               <div class="AboutSection-chil"   :src="item.headIcon" v-for="item in formlist" :key="item.index" :style="item.show?'transform: :translate(30px, 0);   transition: all 1s ease-out;  opacity: 1!important;  box-shadow: 2px 3px 2px 0px #BEBBBB; width: 374px; height: 483px; ':'transition: all .5s ease-out;width: 312px;height: 433px;'"  
               @mouseenter.stop="handleMouseEnter(item)"  @click="onBtn(item.id)"  @mouseleave.stop="handleMouseLeave(item)">
            
            <div class="AboutSection-c">
               <img class="AboutSection-imgxxx" :src="item.image" alt="">
               <div class="AboutSection-xs">
                  <img v-if="item.cost==0" src="../assets/mianfei.png" alt="">
                  <img v-else src="../assets/jinxuan.png" alt="">
                  <span>{{ item.cost>0?'精选活动':'免费活动' }}</span>
               </div>
               <div class="AboutSection-xsx">
                  <img  v-if="item.status==2"  src="../assets/many.png" alt="">
                  <img  v-if="item.status==5"  src="../assets/jies.png" alt="">
                  <img  v-if="item.status==1"  src="../assets/baim.png" alt="">
                  <span v-if="item.status==1">报名中</span>
                  <span v-if="item.status==2">已满员</span>
                  <span v-if="item.status==3||item.status==4 ">报名截止</span>
                  <span v-if="item.status==5">活动结束</span>
               </div>

               <!-- 用户头像 -->
               <div class="AboutSection-icon" :style="item.show?' width: 180px;':' width: 180px;'">
                  <img v-for="items in item.headIconList "   :style="item.show?' margin-right: -10px;  transition: all 1s ease-out;':' margin-right: -17px; transition: all 1s ease-out;'"  class="img-item" :src="items" alt="">
            
                 
               </div>
               <div class="AboutSection-num">已报名 <span style="color: red;">{{ item.number }}</span>人,还有<span style="color: red;">{{ item.enrollment-item.number }}</span>名额</div>
            </div>
            <div class="AboutSection-biaolt">
               {{ item.title }}
            </div>
            <div  class="AboutSection-xe">
               <img src="../assets/time.png" alt="">
               <span>活动时间</span>
               <div :style="item.show?'font-size: 14px;':'font-size: 13px;' " class="AboutSection-right">
               
                  {{ formatDate(item.beginTime) }}-{{ formatDate(item.endTimeMills)  }}
              
               </div>
                </div>
                <div class="AboutSection-xl" v-if="!item.show">
               <div class="AboutSection-cuex">
                  <img src="../assets/shijian @2x(1).png" alt="">
                  <span class="AboutSection-spane">截止时间</span>
                  <span>    {{ formatDate(item.closeTimeMills) }} </span>
               </div>
               <div class="AboutSection-cuex">
                  <img src="../assets/huodongh.png" alt="">
                  <span class="AboutSection-spane">活动名额</span>
                  <span>{{item.enrollment }}</span>
               </div>
               <div class="AboutSection-cuex">
                  <img src="../assets/weizhi.png" alt="">
                  <span class="AboutSection-spane">活动地址</span>
                  <span>{{ item.address }}</span>
               </div>
                </div>
                <div  v-if="item.show" class="AboutSection-x">立即报名</div>
            </div>
          
                
               
     
        
  
             </div>
           </div>

           
     </div>
  
   </section>
    </transition>
   <!-- About section start -->
   <!-- <SharedComponents></SharedComponents> -->
   <!-- About section end -->
</template>

<script>
import SharedComponents from '../components/SharedComponents.vue'

import router from "../router/router"
  import { add } from "../utils/deta.js";
import {getList }from '../axios/dev'
export default {
   data(){
       return{
           testShowxr:false,

formlist:[],



       }
   },

  
   mounted(){
      // this.add()
      getList({
         current:1,
         size:4,
        
         searchType:1,
      }).then(res=>{
         this.formlist=res.data.list
          console.log(this.formlist);
          
       })
   },
   components:{
      SharedComponents
   },
   methods:{
      onHome(){
            router.push({
            path: '/Fellowship',
          
             })

        },
      onBtn(item){
          router.push({
            path: '/SinglesFellowship',
            query: {
              id: item
            }
             })

      },
      formatDate (timestamp){
         const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      return `${year}.${month}.${day} ${hours}:${minutes}`;

      },

// timestampToTime


// 时间戳转换函数


// 示例使用
// 输出：2021-05-26 16:11:25

       handleMouseEnter(item) {
           item.show=true
   },
   handleMouseLeave(item) {
       item.show=false

   }
   },
  

}
</script>

<style scoped>
.AboutSection{
   width: 100%;
   height: 956px;
   /* border: 1px solid #ccc; */
   background: linear-gradient( 180deg, rgba(251,252,255,0) 0%, rgba(207,197,255,0.41) 39%, rgba(204,190,255,0) 100%);
   background-position: center 0px;
   background-size: cover;
   /* background-color: rebeccapurple; */
}
.AboutSection-one{
   margin: 20px auto; 
   font-family: PingFangSC, PingFang SC;
font-weight: 500;
font-size: 56px;
color: #051850;
line-height: 82px;
text-align: center;


font-style: normal;


}
.AboutSection-Single{
 
font-family: PingFangSC, PingFang SC;
font-weight: 400;
font-size: 24px;
color: rgba(0,0,0,0.1);
margin-top: -35px;
letter-spacing: 6px;
text-align: center;
font-style: normal;
text-transform: uppercase;
}
.AboutSection-two{

   /* margin: 0px auto;  */
   font-family: PingFangSC, PingFang SC;
font-weight: 400;
font-size: 24px;
margin-top: 10px;
/* border: 1px solid #ccc; */
color: #999;
line-height: 33px;
text-align: center;
font-style: normal;


}
.AboutSection-img{
   width: 106px;
   height: 113px;
   float: right;
   margin-right: 149px;
   margin-top: -80px;
}
.AboutSection-p{
   width: 70%;
   height: 483px;
   /* background-color: rebeccapurple; */
   /* margin-top: 60px; */
   margin-left: 17%;
   display: flex;
}
.AboutSection-chil{
   /* flex: 1; */
    width: 312px;
    height: 483px;
   /* border: 1px solid #051850; */
   margin-left: 30px;
   border-radius: 10px;
 
   position: relative;
}
.AboutSection-chil:nth-child(even){
    margin-top: 160px;
    width: 312px;
    height: 483px;
    /* color: red; */
}

.AboutSection-gif{
 
   width: 223px;
   height: 218px;
   position: absolute;
   top: -20px;
   left: 78px;
}
.AboutSection-text{
   width: 100%;
   height: 30px;
   /* background-color: darkcyan; */
   text-align: center;
   line-height: 30px;
   /* margin-top: 20px; */

}
.AboutSection-textl{
   width: 100%;
   height: 30px;
  
   /* background-color: darkcyan; */
   text-align: center;
   line-height: 30px;
}
.testenter{
transform: translateY(0%)!important;  
opacity: 1!important;
transition: all 1.5s ease-out;

}

.test{  
       transform: translateY(70%);  
       opacity: 0.5;   
     }
     
     .active{
      
    
      transition-property: all;transition-duration: 2s;transition-timing-function: linear;
      box-shadow:5px 5px px px #636363,
     }
     .AboutSection-c {
      position: relative;
     }
     .AboutSection-imgxxx{
      width: 100%;
      height: 226px;
      /* background-color: rebeccapurple; */
      /* background:url('https://online-miniapp-1304146125.cos.ap-beijing.myqcloud.com/903c6d5b-3739-4615-b974-73ae067b5b48.png') no-repeat center 0px ;
	   background-position: center 0px;
      background-size: cover; */
      border-radius: 9px 9px 0px 0px;
     

     }
     .AboutSection-xs{
      width: 90px;
height: 24px;
background: rgba(0,0,0,0.6);
border-radius: 27px;

position: absolute;
top: 10px;
left: 10px;

     }
     .AboutSection-xs img{
      width: 24px;
      height: 24px;

     }
     .AboutSection-xs span{
      font-family: AlibabaPuHuiTiR;
font-size: 12px;
color: #FFFFFF;
/* line-height: 17px; */
position: absolute;
top: 3px;
left: 30px;
font-style: normal;

     }
     .AboutSection-xsx{
      width: 90px;
height: 24px;
background: rgba(0,0,0,0.6);
border-radius: 27px;

position: absolute;
top: 10px;
left: 120px;

     }
     .AboutSection-xsx img{
      width: 24px;
      height: 24px;

     }
     .AboutSection-xsx span{
      font-family: AlibabaPuHuiTiR;
font-size: 12px;
color: #FFFFFF;
/* line-height: 17px; */
position: absolute;
top: 3px;
left: 35px;
font-style: normal;

     }
     .AboutSection-icon{
      
      height: 33px;
      /* border: 1px solid #051850; */
      position: absolute;
      left: 5px;
      bottom: 14px;
      display: flex;
      flex-wrap: wrap;
    
      align-content: flex-start;
     }
      .img-item {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: -18px;
    }
    .AboutSection-num{
      font-family: PingFangSC, PingFang SC;
font-weight: 500;
font-size: 14px;
color: #FFFFFF;
line-height: 20px;
text-align: left;
font-style: normal;
position: absolute;
right: 18px;
bottom: 16px;
    }
    .AboutSection-biaolt{
      width: 94%;
      padding: 5px;
      /* height: 200px; */
      margin-left: 3%;
      /* background-color: darkgoldenrod; */
      font-family: PingFangSC, PingFang SC;
font-weight: 500;
font-size: 20px;
color: #222222;
line-height: 33px;
text-align: left;
font-style: normal;

    }
  .AboutSection-xe{
   width: 93%;
   margin-left: 4%;
   height:50px ;
   background: #E3D6FF;
border-radius: 11px;
position: relative;

  }
  .AboutSection-xe img{
   height: 50px;
   width: 64px;
   position: absolute;
   top: -2px;
   left: -5px;
  }
  .AboutSection-xe span{
   font-family: PingFangSC, PingFang SC;
font-weight: 500;
font-size: 12px;
color: #FFFFFF;
/* line-height: 20px; */
text-align: left;
font-style: normal;
position: absolute;
top: 15px;
left: 5px;
  }
  .AboutSection-right{
   width: 70%;
  height: 50px;
  margin-left: 22%;
  /* float: right; */
  overflow:hidden;
  /* background-color: darkcyan; */
  text-align: center;
  padding-left: 15px;
  line-height: 50px;
  font-family: PingFangSC, PingFang SC;
font-weight: 400;

color: #333333;


  }
  .AboutSection-xl{
   width: 100%;
   height: 100px;
   /* background-color: rgba(0,0,0,0.6); */
   margin-top: 10px;
   display: flex;
   flex-direction: column;

  }
  .AboutSection-cuex{
   flex: 1;
   /* border: 1px solid #ccc; */
   position: relative;
  }
  .AboutSection-cuex img{
   width: 24px;
   height: 24px;
 
  position: absolute;
  top: 1px;
  left: 15px;

  }
  .AboutSection-spane{
   font-family: PingFangSC, PingFang SC;
font-weight: 400;
font-size: 14px;
color: #999999;

position: absolute;
top: 3px;
left: 46px;

  }
  .AboutSection-cuex span:nth-child(3){
   width: 180px;
   font-family: PingFangSC, PingFang SC;
font-weight: 400;
font-size: 14px;
color: #000;
line-height: 20px;
overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;
/* background-color: rebeccapurple; */
font-style: normal;
position: absolute;
top: 3px;
left: 120px;


  }
  .AboutSection-x{
   width: 90%;
   height: 48px;
   line-height: 48px;
   margin-left: 5%;
   text-align: center;
   font-family: PingFangSC, PingFang SC;
font-weight: 400;
font-size: 18px;
color: #FFFFFF;
background-color: #783DF9;
font-style: normal;
border-radius: 10px;
text-transform: uppercase;
margin-top: 30px;
  }
</style>